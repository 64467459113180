import React from 'react';
import { useParams, Link } from 'react-router-dom';

const blogPosts = [
  { id: 1, title: 'Post 1', date: 'September 15, 2024', image: 'path/to/image1.jpg', content: 'Full content of post 1...' },
  { id: 2, title: 'Post 2', date: 'October 1, 2024', image: 'path/to/image2.jpg', content: 'Full content of post 2...' },
  { id: 3, title: 'Post 3', date: 'November 5, 2024', image: 'path/to/image3.jpg', content: 'Full content of post 3...' },
  { id: 4, title: 'How to Build the Future', date: 'Featured Post', image: 'featured-image-url.jpg', content: 'Learn how to build your future with modern web technologies...' },
];

const BlogPost = () => {
  const { id } = useParams();
  const post = blogPosts.find(p => p.id === parseInt(id));

  if (!post) {
    return <p className="text-center text-xl text-red-600">Post not found</p>;
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 dark:text-gray-100">
      {/* Hero Section */}
      <div className="relative bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-white py-16 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">{post.title}</h1>
          <p className="text-lg">{post.date}</p>
        </div>
      </div>

      {/* Post Content */}
      <div className="max-w-4xl mx-auto p-8 mt-12 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
        <img src={post.image} alt={post.title} className="rounded-lg mb-6 w-full object-cover h-72" />
        <div className="post-content text-gray-700 dark:text-gray-300">
          <p className="text-lg leading-relaxed mb-6">{post.content}</p>
        </div>
        <Link 
          to="/blog" 
          className="inline-block mt-8 px-4 py-2 bg-blue-600 dark:bg-blue-500 text-white rounded-lg hover:bg-blue-700 dark:hover:bg-blue-600 transition-all"
        >
          Back to Blog
        </Link>
      </div>

      {/* Matching Call to Action Section */}
      <div className="relative bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-white py-16 px-4 mt-16">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-4">Enjoyed this Post?</h2>
          <p className="text-lg font-light mb-8">Explore more posts or join our community to stay updated!</p>
          <Link to="/blog" className="bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg text-lg font-semibold transition-all text-white">Back to Blog</Link>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
