import React, { useEffect, useState } from 'react';

const Home = () => {
  const [serverStatus, setServerStatus] = useState({
    online: false,
    playersOnline: 0,
  });

  // Fetch server status from mcsrvstat.us API
  useEffect(() => {
    const fetchServerStatus = async () => {
      try {
        const response = await fetch('https://api.mcsrvstat.us/2/play.mystipixel.com');
        const data = await response.json();

        setServerStatus({
          online: data.online,
          playersOnline: data.players?.online || 0, // Default to 0 if no player info
        });
      } catch (error) {
        console.error('Failed to fetch server status:', error);
        setServerStatus({ online: false, playersOnline: 0 });
      }
    };

    fetchServerStatus();
  }, []);

  return (
    <div className="bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      {/* Full-Screen Hero Section */}
      <section className="h-screen flex flex-col justify-center items-center text-center">
        <h1 className="text-6xl font-bold text-gray-900 dark:text-white">Welcome to MystiPixel</h1>
        <p className="text-lg text-gray-600 dark:text-gray-400 mt-4">
          Join our thriving Minecraft community filled with adventure, challenges, and fun!
        </p>

        {/* Server Status Card */}
        <div className="mt-8 p-6 bg-gray-200 dark:bg-gray-800 rounded-lg shadow-xl max-w-xs w-full">
          <div className="mb-4">
            <h2 className="text-xl font-bold text-gray-900 dark:text-white">Server Status</h2>
            <p className={`mt-2 ${serverStatus.online ? "text-green-500" : "text-red-500"}`}>
              {serverStatus.online ? "Online" : "Offline"}
            </p>
          </div>
          
          {serverStatus.online && (
            <div className="mb-4">
              <h3 className="text-lg text-gray-900 dark:text-gray-200">Players Online</h3>
              <p className="text-blue-500">{serverStatus.playersOnline}</p>
            </div>
          )}

          {/* Play Now Button */}
          <a
            href="minecraft://play.mystipixel.com"
            className="inline-block mt-4 px-6 py-3 bg-blue-600 dark:bg-blue-500 text-white rounded-lg hover:bg-blue-700 dark:hover:bg-blue-600 transition-all w-full text-center"
          >
            Play Now
          </a>
        </div>
      </section>

      {/* Alternating Server Features Section */}
      <section className="my-16">
        <h2 className="text-4xl font-bold text-center text-gray-900 dark:text-white">Server Features</h2>
        <div className="mt-12 space-y-16 max-w-5xl mx-auto">
          {/* Feature 1 */}
          <div className="flex flex-col md:flex-row items-center md:space-x-8">
            <img src="https://via.placeholder.com/300" alt="Feature 1" className="w-full md:w-1/2 rounded-lg shadow-lg" />
            <div className="mt-8 md:mt-0">
              <h3 className="text-3xl font-bold text-gray-900 dark:text-white">Skyblock Adventures</h3>
              <p className="mt-4 text-gray-700 dark:text-gray-300">
                Explore our custom Skyblock world with unique islands, challenges, and a friendly community.
              </p>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="flex flex-col md:flex-row-reverse items-center md:space-x-8">
            <img src="https://via.placeholder.com/300" alt="Feature 2" className="w-full md:w-1/2 rounded-lg shadow-lg" />
            <div className="mt-8 md:mt-0">
              <h3 className="text-3xl font-bold text-gray-900 dark:text-white">Mini-Games Galore</h3>
              <p className="mt-4 text-gray-700 dark:text-gray-300">
                Join in on our thrilling mini-games, from parkour challenges to PvP arenas.
              </p>
            </div>
          </div>

          {/* Feature 3 */}
          <div className="flex flex-col md:flex-row items-center md:space-x-8">
            <img src="https://via.placeholder.com/300" alt="Feature 3" className="w-full md:w-1/2 rounded-lg shadow-lg" />
            <div className="mt-8 md:mt-0">
              <h3 className="text-3xl font-bold text-gray-900 dark:text-white">Custom Plugins</h3>
              <p className="mt-4 text-gray-700 dark:text-gray-300">
                Experience Minecraft like never before with our custom plugins designed to enhance your gameplay.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="text-center mb-16">
        <h2 className="text-4xl font-bold text-gray-900 dark:text-white">Ready to Join?</h2>
        <p className="mt-4 text-gray-700 dark:text-gray-300">
          Explore our community and start your adventure today!
        </p>
        <a
          href="minecraft://play.mystipixel.com"
          className="inline-block mt-6 px-6 py-3 bg-blue-600 dark:bg-blue-500 text-white rounded-lg hover:bg-blue-700 dark:hover:bg-blue-600 transition-all"
        >
          Play Now
        </a>
      </section>
    </div>
  );
};

export default Home;
