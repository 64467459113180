import React from 'react';
import { FaBug, FaStar, FaRocket } from 'react-icons/fa';

const Changelog = () => {
  // Dummy data for changelog entries
  const changelogEntries = [
    {
      version: 'v1.2.0',
      date: 'July 2024',
      description: 'Added new user dashboards, improved security features, and enhanced performance optimizations.',
      type: 'feature',
    },
    {
      version: 'v1.1.5',
      date: 'June 2024',
      description: 'Fixed various bugs, improved platform stability, and updated the UI for better user experience.',
      type: 'bugfix',
    },
    {
      version: 'v1.0.0',
      date: 'May 2024',
      description: 'Initial release of the platform with core features including user management, dashboard, and analytics.',
      type: 'release',
    },
  ];

  // Function to return an icon based on the type of changelog entry
  const getIcon = (type) => {
    switch (type) {
      case 'feature':
        return <FaStar className="text-yellow-500 dark:text-yellow-300 text-3xl" />;
      case 'bugfix':
        return <FaBug className="text-red-500 dark:text-red-300 text-3xl" />;
      case 'release':
        return <FaRocket className="text-green-500 dark:text-green-300 text-3xl" />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen p-8 bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      {/* Page Title */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold">Changelog</h1>
        <p className="text-gray-500 dark:text-gray-400 mt-4">Stay updated with the latest improvements and releases.</p>
      </div>

      {/* Timeline Container */}
      <div className="relative">
        {/* Vertical Timeline Line */}
        <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-gray-300 dark:bg-gray-600"></div>

        {/* Changelog Entries */}
        <div className="space-y-12">
          {changelogEntries.map((entry, index) => (
            <div
              key={index}
              className={`relative flex flex-col md:flex-row md:items-center ${
                index % 2 === 0 ? 'md:justify-start' : 'md:flex-row-reverse'
              } items-start`}
            >
              {/* Icon for Desktop */}
              <div className="hidden md:block absolute left-1/2 transform -translate-x-1/2 bg-white dark:bg-gray-900 p-2 rounded-full shadow-lg z-10">
                {getIcon(entry.type)}
              </div>

              {/* Changelog Card */}
              <div
                className={`w-full md:w-5/12 p-6 rounded-lg shadow-xl bg-gray-200 dark:bg-gray-800 transform transition-all duration-300 hover:scale-105 hover:shadow-2xl`}
              >
                {/* Icon for Mobile */}
                <div className="block md:hidden mb-4">
                  {getIcon(entry.type)}
                </div>

                <h2 className="text-2xl font-bold text-gray-900 dark:text-white">{entry.version}</h2>
                <p className="text-sm text-gray-600 dark:text-gray-100 mt-1">{entry.date}</p>
                <p className="mt-4 text-gray-800 dark:text-white">{entry.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Changelog;
