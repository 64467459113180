import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { FaSun, FaMoon, FaBars, FaTimes } from 'react-icons/fa';
import Home from './pages/Home';
import BlogListing from './pages/BlogListing'; // Blog Listing component
import BlogPost from './pages/BlogPost'; // Individual Blog Post component
import Changelog from './pages/Changelog';
import Roadmap from './pages/Roadmap';
import Contact from './pages/Contact';
import './App.css';

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme === 'dark') {
      setDarkMode(true);
      document.documentElement.classList.add('dark');
    } else {
      setDarkMode(false);
      document.documentElement.classList.remove('dark');
    }
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    if (!darkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Router>
      <div className="App">
        <header className="fixed w-full top-0 bg-gray-800 dark:bg-gray-900 text-white shadow-lg z-20">
          <nav className="max-w-screen-xl mx-auto p-4">
            <div className="flex justify-between items-center">
              <div className="text-lg font-bold">
                <Link to="/" className="App-link">
                  MyWebsite
                </Link>
              </div>

              <div className="md:hidden">
                <button onClick={toggleMenu} className="text-white focus:outline-none">
                  {menuOpen ? <FaTimes className="w-6 h-6" /> : <FaBars className="w-6 h-6" />}
                </button>
              </div>

              <ul className="hidden md:flex items-center space-x-6">
                <li>
                  <Link to="/" className="hover:underline">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/blog" className="hover:underline">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to="/changelog" className="hover:underline">
                    Changelog
                  </Link>
                </li>
                <li>
                  <Link to="/roadmap" className="hover:underline">
                    Roadmap
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="hover:underline">
                    Contact
                  </Link>
                </li>
                <li>
                  <button
                    onClick={toggleDarkMode}
                    className="text-white focus:outline-none bg-gray-700 p-2 rounded-full ml-4"
                  >
                    {darkMode ? <FaSun className="text-yellow-400" /> : <FaMoon className="text-blue-400" />}
                  </button>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <div
          className={`fixed inset-0 bg-gray-900 bg-opacity-90 dark:bg-gray-800 dark:bg-opacity-90 z-10 flex flex-col items-center justify-center space-y-8 transform transition-transform duration-300 ${
            menuOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <ul className="text-center space-y-6 text-white text-2xl">
            <li>
              <Link to="/" onClick={toggleMenu} className="hover:text-yellow-400">
                Home
              </Link>
            </li>
            <li>
              <Link to="/blog" onClick={toggleMenu} className="hover:text-yellow-400">
                Blog
              </Link>
            </li>
            <li>
              <Link to="/changelog" onClick={toggleMenu} className="hover:text-yellow-400">
                Changelog
              </Link>
            </li>
            <li>
              <Link to="/roadmap" onClick={toggleMenu} className="hover:text-yellow-400">
                Roadmap
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={toggleMenu} className="hover:text-yellow-400">
                Contact
              </Link>
            </li>
          </ul>
          <button
            onClick={toggleDarkMode}
            className="text-white focus:outline-none bg-gray-700 p-4 rounded-full mt-4"
          >
            {darkMode ? <FaSun className="text-yellow-400 text-3xl" /> : <FaMoon className="text-blue-400 text-3xl" />}
          </button>
        </div>

        <div className="mt-16 p-8 dark:bg-gray-900 dark:text-gray-100">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<BlogListing />} /> 
            <Route path="/blog/:id" element={<BlogPost />} />
            <Route path="/changelog" element={<Changelog />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
