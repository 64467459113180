import React from 'react';
import { Link } from 'react-router-dom';

const BlogListing = () => {
  const blogPosts = [
    { id: 1, title: 'Post 1', date: 'September 15, 2024', image: 'path/to/image1.jpg', excerpt: 'Summary of post 1...' },
    { id: 2, title: 'Post 2', date: 'October 1, 2024', image: 'path/to/image2.jpg', excerpt: 'Summary of post 2...' },
    { id: 3, title: 'Post 3', date: 'November 5, 2024', image: 'path/to/image3.jpg', excerpt: 'Summary of post 3...' },
    { id: 4, title: 'How to Build the Future', date: 'Featured Post', image: 'featured-image-url.jpg', excerpt: 'Learn how to build your future...' },
    { id: 5, title: 'Next-Level Technology', date: 'Featured Post', image: 'second-featured-image-url.jpg', excerpt: 'Embrace next-level technology...' },
  ];

  const featuredPosts = blogPosts.filter(post => post.id === 4 || post.id === 5);

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 dark:text-gray-100">
      {/* Hero Section */}
      <div className="relative bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-white py-16 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-5xl font-bold mb-4">Welcome to Our Inspiring Blog</h1>
          <p className="text-xl font-light mb-8 text-gray-700 dark:text-gray-300">Explore the latest posts, insights, and stories from our vibrant community.</p>
          <a href="#blog" className="inline-block bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg text-lg font-medium transition-all text-white">Discover Posts</a>
        </div>
      </div>

      {/* Featured Posts */}
      <div className="max-w-6xl mx-auto mt-12 mb-16">
        <h2 className="text-3xl font-bold text-gray-900 dark:text-white text-center mb-8">Featured Posts</h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {featuredPosts.map(post => (
            <div key={post.id} className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
              <img src={post.image} alt={post.title} className="rounded-lg mb-6 w-full object-cover h-64" />
              <h3 className="text-3xl font-bold mb-4 text-gray-900 dark:text-white">{post.title}</h3>
              <p className="text-gray-700 dark:text-gray-300 mb-4">{post.excerpt}</p>
              <a href={`/blog/${post.id}`} className="bg-blue-600 dark:bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700 dark:hover:bg-blue-600 transition-all">Read More</a>
            </div>
          ))}
        </div>
      </div>

      {/* Blog Posts */}
      <div id="blog" className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {blogPosts.slice(0, 3).map(post => (
          <div key={post.id} className="relative group bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden">
            <img src={post.image} alt={post.title} className="h-56 w-full object-cover group-hover:scale-110 transition-transform duration-500" />
            <div className="p-6">
              <h3 className="text-2xl font-bold text-gray-900 dark:text-white group-hover:text-blue-600 transition-colors">{post.title}</h3>
              <p className="mt-2 text-gray-700 dark:text-gray-300">{post.excerpt}</p>
              <Link to={`/blog/${post.id}`} className="inline-block mt-4 text-blue-600 dark:text-blue-400 hover:underline">Read More</Link>
            </div>
          </div>
        ))}
      </div>

      {/* Matching Call to Action Section */}
      <div className="relative bg-gray-200 dark:bg-gray-800 text-gray-900 dark:text-white py-16 px-4 mt-16">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-4">Stay Connected with Us</h2>
          <p className="text-lg font-light mb-8">Join our community and never miss an update from our blog!</p>
          <Link to="/contact" className="bg-blue-600 hover:bg-blue-700 px-6 py-3 rounded-lg text-lg font-semibold transition-all text-white">Contact Us</Link>
        </div>
      </div>
    </div>
  );
};

export default BlogListing;
