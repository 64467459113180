import React from 'react';
import { FaCheckCircle, FaSpinner, FaRocket } from 'react-icons/fa';

const Roadmap = () => {
  // Dummy data for roadmap items
  const roadmapItems = [
    {
      stage: 'In Progress',
      title: 'Feature Expansion: User Profiles',
      date: 'Expected Q4 2024',
      description: 'We are expanding user profiles with more customization options and social integration.',
      type: 'inprogress',
    },
    {
      stage: 'Upcoming',
      title: 'New Analytics Dashboard',
      date: 'Expected Q1 2025',
      description: 'A brand new analytics dashboard to track real-time performance metrics.',
      type: 'upcoming',
    },
    {
      stage: 'Completed',
      title: 'Initial Release: v1.0',
      date: 'Released Q3 2024',
      description: 'We successfully launched the platform with core features and user authentication.',
      type: 'completed',
    },
  ];

  // Function to return an icon based on the stage of roadmap item
  const getIcon = (type) => {
    switch (type) {
      case 'inprogress':
        return <FaSpinner className="text-blue-500 dark:text-blue-300 text-3xl" />;
      case 'upcoming':
        return <FaRocket className="text-yellow-500 dark:text-yellow-300 text-3xl" />;
      case 'completed':
        return <FaCheckCircle className="text-green-500 dark:text-green-300 text-3xl" />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen p-8 bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      {/* Page Title */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold">Product Roadmap</h1>
        <p className="text-gray-500 dark:text-gray-400 mt-4">See our upcoming features and development progress!</p>
      </div>

      {/* Timeline Container */}
      <div className="relative">
        {/* Vertical Timeline Line */}
        <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-gray-300 dark:bg-gray-600"></div>

        {/* Roadmap Entries */}
        <div className="space-y-12">
          {roadmapItems.map((item, index) => (
            <div
              key={index}
              className={`relative flex flex-col md:flex-row md:items-center ${
                index % 2 === 0 ? 'md:justify-start' : 'md:flex-row-reverse'
              } items-start`}
            >
              {/* Icon for Desktop */}
              <div className="hidden md:block absolute left-1/2 transform -translate-x-1/2 bg-white dark:bg-gray-900 p-2 rounded-full shadow-lg z-10">
                {getIcon(item.type)}
              </div>

              {/* Roadmap Card */}
              <div
                className={`w-full md:w-5/12 p-6 rounded-lg shadow-xl bg-gray-200 dark:bg-gray-800 transform transition-all duration-300 hover:scale-105 hover:shadow-2xl`}
              >
                {/* Icon for Mobile */}
                <div className="block md:hidden mb-4">
                  {getIcon(item.type)}
                </div>

                <h2 className="text-2xl font-bold text-gray-900 dark:text-white">{item.stage}: {item.title}</h2>
                <p className="text-sm text-gray-600 dark:text-gray-100 mt-1">{item.date}</p>
                <p className="mt-4 text-gray-800 dark:text-white">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
